import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Title from "../title"
import Button from "../button"
import { GatsbyImage } from "gatsby-plugin-image"
import cn from "classnames"
import * as styles from "./hero.module.scss"

export default function Hero({ className }) {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <section className={cn(styles.hero, className)}>
          <div className={styles.left}>
            <Title headingLevel="h4">Frontend Developers For Hire</Title>
            <Title headingLevel="h1" className={styles.title}>
              We build,
              <br />
              <mark>fast sites & apps.</mark>
            </Title>
            <Title headingLevel="p" className={styles.heroDescription}>
              Maximize your business potential with performant websites built
              with the latest and innovative technologies.
            </Title>
            <span>
              <Button to="/start-project">Get in touch</Button>
            </span>
          </div>
          <div className={styles.right}>
            <GatsbyImage
              image={data.hero.sharp.gatsbyImageData}
              alt="Hero Image"
            />
          </div>
        </section>
      )}
    />
  )
}

export const query = graphql`
  {
    hero: file(relativePath: { eq: "fast.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`
