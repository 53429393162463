import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Products from "../components/products"
import Technologies from "../components/technologies"
import FooterCallToAction from "../components/footer/call-to-action"
import * as styles from "./index.module.scss"

export default function Home() {
  return (
    <>
      <Seo title="Grycode: Frontend Development Solutions | London" />
      <Layout footerChildren={<FooterCallToAction />}>
        <Hero />
        <Products
          className={styles.services}
          subTitle="Services"
          title="What we do"
          highlight
        />

        <Technologies />
      </Layout>
    </>
  )
}
