import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Title from "../title"
import cn from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./products.module.scss"

export default function Products({
  title,
  subTitle,
  description,
  className,
  highlight = false,
}) {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <section
          className={cn(
            styles.products,
            className,
            highlight ? styles.highlight : null
          )}
        >
          <header className={styles.header}>
            {subTitle && <Title headingLevel="h4">{subTitle}</Title>}
            {title && <Title headingLevel="h2">{title}</Title>}
            {description && <Title headingLevel="p">{description}</Title>}
          </header>
          <div className={styles.content}>
            <Link
              to="/services/frontend-development"
              className={styles.product}
            >
              <GatsbyImage image={data.frontend.sharp.gatsbyImageData} alt="" />
              <Title headingLevel="h3">Frontend Development</Title>
              <p className={styles.description}>
                Using cutting-edge front-end development tools to produce
                user-friendly, fully functioning, and high-performance front-end
                solutions.
              </p>
              <span className={styles.more}>See more »</span>
            </Link>
            <Link
              to="/services/user-interface-development"
              className={styles.product}
            >
              <GatsbyImage image={data.ui.sharp.gatsbyImageData} alt="" />
              <Title headingLevel="h3">UI/UX Development</Title>
              <p className={styles.description}>
                Integrating UX/UI designs and wireframes into a user-friendly,
                mobile-first, accessible, and intuitive interfaces
              </p>
              <span className={styles.more}>See more »</span>
            </Link>
            <Link to="/services/jamstack-website" className={styles.product}>
              <GatsbyImage image={data.speed.sharp.gatsbyImageData} alt="" />
              <Title headingLevel="h3">JAMstack Websites</Title>
              <p className={styles.description}>
                Increase your chances of gaining new consumers by having a
                blazing fast website with better SEO. We build them with proven
                technologies: GatsbyJS, Next.js, Netlify, and many others.
              </p>
              <span className={styles.more}>See more »</span>
            </Link>
          </div>
        </section>
      )}
    />
  )
}

export const query = graphql`
  {
    frontend: file(relativePath: { eq: "frontend.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }

    ui: file(relativePath: { eq: "user-interface.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }

    speed: file(relativePath: { eq: "jamstack-speed.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`
