import React from "react"
import Title from "../../title"
import Button from "../../button"
import * as styles from "./call-to-action.module.scss"

export default function FooterCallToAction() {
  return (
    <section className={styles.callToAction}>
      <Title headingLevel="h4">Frontend developers for hire</Title>
      <Title headingLevel="h2">Ready to go fast?</Title>
      <Title headingLevel="p">
        Take your existing project to the next level. Or start a new one.
      </Title>
      <Button to="/start-project">Start project</Button>
    </section>
  )
}
